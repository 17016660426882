/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-07-29",
    versionChannel: "nightly",
    buildDate: "2024-07-29T00:05:42.096Z",
    commitHash: "f6ca9bcdaccc3401cac300ed989f235f3a0d1056",
};
